import emptyData from "@/components/empty-data/index.vue"
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {find} from "lodash";

export default {
  name:'articles',
  data(){
    return{
      currentCategory: null
    }
  },
  components:{
    emptyData
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  created() {
    if (!this.articlesList.length) {
      this.fetchArticlesList().then(()=>{
        if (this.articlesList.length) {
          this.currentCategory = this.articlesList[0]
        }
      });
    } else {
      if (this.articlesList.length) { this.currentCategory = this.articlesList[0]}
    }
  },
  computed: {
    ...mapGetters({
      articlesList: 'articles/articlesList',
      isLoadingArticles: 'articles/isLoadingArticles',
    })
  },
  methods: {
    ...mapActions({
      fetchArticlesList: `articles/ARTICLES_LIST_GET`,
    }),
    showCategory(id) {
      this.currentCategory = null;
      this.currentCategory = find(this.articlesList, (item) => {
        return item.id === id;
      })
    }
  },
}
